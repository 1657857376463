/* CSS reset with box-sizing fix */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

html {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-size: 14px;
    line-height: 20px;
    color: #333;
    font-weight: 400;
    &.popup-open {
        overflow: hidden;
    }
}

img {
    vertical-align: top;
}

ol,
ul,
li {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

textarea {
    resize: none;
}

legend,
caption,
.hide {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    font-size: 0;
    line-height: 0;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    color: inherit;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0px;
    font-size: 14px;
}

input,
select,
button {
    -webkit-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0px;
    outline: none;
    border: 0;
    margin: 0;
    padding: 0;
    background: none;
}

html,
body {
    width: 100%;
    height: 100%;
    font-family: 'notosans';
}

body {
    *{
        font-family: 'notosans';
    }
    .luck *{
        font-family: 'Noto Serif KR', serif;
    }
}