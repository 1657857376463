button {
    @include resetButton();
}

.wrap {
    height: 100%;
    position: relative;
    &--overflow {
        overflow: hidden;
    }
    &.skin-blue {
        .category {
            background-color: #0da8ff;
            border-bottom: none;
            &__anchor {
                color: #fff;
                &.active {
                    &::after {
                        background: #fff;
                    }
                }
            }
            &__button {
                background-image: linear-gradient(to right, rgba(13, 168, 255, 0), #0da8ff 25%);
                background-image: -webkit-linear-gradient(to right, rgba(13, 168, 255, 0), #0da8ff 25%);
                &:before {
                    background-image: url(../images/icon-view03.svg);
                    background-color: #0da8ff;
                }
            }
        }
        .category-layer__content {
            .category__anchor {
                color: #9da3ac;
                &.active {
                    color: #333;
                }
            }
        }
        .category2 {
            background-color: #0898e9;
            &__anchor {
                color: #fff;
                &.active {
                    font-weight: 500;
                }
            }
        }
    }
    .category-layer__content {
        .category__anchor {
            display: inline-block;
            float: none;
        }
    }
}

.content {
    width: 100%;
    min-height: 100%;
    padding: 50px 0 70px;
    background: #fff;
    &--type2 {
        padding-top: 100px;
    }
    &--type3 {
        padding-top: 140px;
    }
    &--type4 {
        padding-top: 0;
    }
    &--type5 {
        padding-top: 90px;
    }
    &--all {
        padding: 0;
        height: 100%;
    }
    &--background {
        background: #f5f7fa;
    }
    &--background2 {
        background-color: #0da8ff;
    }
    &--height-100 {
        height: 100%;
    }
    &--padding-0 {
        padding-bottom: 0;
    }
    &--padding-interval {
        padding-bottom: 20px;
    }
    &.pc {
        @include desktop-width {
            margin-bottom: -110px;
        }
    }
    // @include desktop-width {
    //     width: 50%;
    //     max-width: 525px;
    //     margin: 0 0 0 50%;
    //     border-left: 1px solid #f5f7fa;
    //     border-right: 1px solid #f5f7fa
    // }
    .container-center {
        width: calc(100% - 100px);
        @include position-center();
        text-align: center;
    }
    .center-button {
        padding-top: 28px;
        .button+.button {
            margin-top: 8px;
        }
        &--type2 {
            padding-top: 48px;
        }
    }
    .container-center-interval {
        padding-top: 4px;
    }
    .flex-aligner {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        &__item {
            margin: auto;
            &--padding {
                width: 100%;
                padding: 0 70px;
            }
            &--padding-type2 {
                width: 100%;
                padding: 0 15px;
            }
        }
    }
}

.container-interval {
    padding: 0 15px;
    &--type2 {
        padding: 24px 15px;
        height: 100%;
    }
    &--type3 {
        height: 100%;
    }
    &+& {
        margin-top: 12px;
    }
}

.layer-popup {
    position: fixed;
    z-index: 999;
    width: 100%;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    &--type2 {
        bottom: -50px;
        //z-index: 102;
        display: none;
    }
    &--type3 {
        bottom: -500px;
    }
}

.popup {
    position: fixed;
    display: none;
    bottom: 50px;
    background: #fff;
    width: 100%;
    height: 200px;
    &__close {
        width: 50px;
        height: 50px;
        background: url(../images/icon-close.svg) no-repeat center;
        background-size: 24px 24px;
        position: absolute;
        top: 4px;
        right: 3px;
    }
    &__content {
        text-align: center;
        padding: 44px 15px 20px;
        &--type2 {
            padding-top: 30px;
        }
    }
    .login {
        &__sns {
            @include flex(center, space-between);
            padding: 20px 48px 0;
            li {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                overflow: hidden;
                img {
                    width: 40px;
                    height: 40px;
                }
            }
        }
        &__sub {
            padding-top: 6px;
        }
    }
    .member {
        &__label {
            display: inline-block;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            color: #0da8ff;
            border-radius: 3px;
            border: 1px solid #0da8ff;
            padding: 3px 4px;
            vertical-align: text-top;
            transform: translateY(30%);
            &--fa {
                padding: 3px 14px;
            }
        }
        &__box {
            @include flex(center, space-between);
            padding-top: 22px;
        }
        &__inner {
            width: 48%;
            background: #f5f7fa;
            border: 1px solid #d5e0ef;
            border-radius: 5px;
            padding: 20px 19px;
            min-height: 40px;
            box-sizing: border-box;
            a {
                display: block;
                color: #333;
            }
            &--scrap {
                background: #e9fffe;
                border: 1px solid #c3f3f1;
            }
            &--type2 {
                padding: 10px 20px 16px;
                min-height: 60px;
            }
            &--type3 {
                @include flex(center, space-between);
                padding: 6px 17px;
            }
            &--width-small {
                width: 33%;
            }
            &--width-wide {
                width: 65%;
            }
            .number {
                padding-top: 10px;
                span {
                    //text-decoration: underline;
                    font-weight: 500;
                    position: relative;
                    &::before {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 1px;
                        background-color: #0da8ff;
                        bottom: 5px;
                    }
                }
                .colored {
                    color: #04d6cd;
                    &::before {
                        background-color: #04d6cd;
                    }
                }
            }
            .qna {
                &__list {
                    padding: 7px 0 7px 36px;
                    background-image: url(../images/icon-qna_question.svg);
                    background-repeat: no-repeat;
                    background-size: 16px;
                    background-position: center left;
                    border-bottom: 1px solid #c3f3f1;
                    box-sizing: border-box;
                    &:last-child {
                        border-bottom: none;
                    }
                    &--type2 {
                        background-image: url(../images/icon-qna_answer.svg);
                    }
                }
            }
        }
        .visitors {
            @include flex(center, center);
            &__row {
                padding: 0 15px;
                position: relative;
                font-size: 12px;
                &:after {
                    content: '';
                    display: inline-block;
                    width: 1px;
                    height: 12px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    @include vertical-center();
                    background: #9da3ac;
                }
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
            &__title {
                color: #9da3ac;
            }
        }
    }
    // @include desktop-width {
    //     width: 50%;
    //     max-width: 525px;
    //     margin: 0 0 0 50%;
    // }
}

.notice-popup {
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100% - 80px);
    @include position-center();
    border-radius: 5px;
    border: solid 2px #0da8ff;
    background-color: #ffffff;
    bottom: -50px;
    &--type2 {
        //height: calc(100% - 272px);
        height: auto;
        //bottom: 128px;
        border: none;
        border-radius: 0;
        bottom: auto;
        .main-slider__item {
            padding-left: 0;
        }
    }
    @include desktop-width {
        width: 50%;
        max-width: 380px;
        //margin: 0 0 0 50%;
    }
}

.not-scroll {
    overflow: hidden;
    position: fixed;
    //top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &--type2 {
        overflow: hidden;
        height: 100%;
    }
}

.main-slider {
    $root: &;
    padding-bottom: 42px;
    background: #fff;
    &__item {
        width: 100%;
        height: auto!important;
        padding-right: 15px;
        padding-left: 15px;
        img {
            width: 100%;
            height: auto;
        }
    }
    &__image {
        display: block;
        &--type2 {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 64px;
                height: 64px;
                border-radius: 100%;
                background-color: rgba(255, 255, 255, 0.8);
                background-image: url(../images/icon-media.svg);
                background-size: 35px 34px;
                background-position: 12px 12px;
                background-repeat: no-repeat;
            }
        }
    }
    &__play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 64px;
        height: 64px;
        border-radius: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        background-image: url(../images/icon-media.svg);
        background-size: 35px 34px;
        background-position: 12px 12px;
        background-repeat: no-repeat;
    }
    .slick-dots {
        width: 100%;
        right: 0;
        bottom: 21px;
        text-align: center;
        li {
            button {
                width: 6px;
                height: 6px;
                &:before {
                    width: 6px;
                    height: 6px;
                    background: #dde4ed;
                    border: 1px solid #dde4ed;
                }
            }
            &.slick-active {
                button {
                    &:before {
                        width: 6px;
                        height: 6px;
                        background: #05a4e8;
                    }
                }
            }
        }
    }
    &--type2 {
        padding-bottom: 0;
        //height: 400px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
        }
        #{$root}__item {
            padding-right: 0;
            padding-left: 0;
        }
        .slick-dots {
            bottom: 8px;
        }
        .slick-prev {
            position: absolute;
            top: 50%;
            width: 40px;
            height: 40px;
            //transform: translateY(-25px);
            background-image: url(../images/icon-slider-button.svg);
            background-size: cover;
            z-index: 10;
            left: 4px;
            &::before {
                position: absolute;
                content: "";
                width: 24px;
                height: 24px;
                background-image: url(../images/icon-slider-button_03.svg);
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            &:hover,
            &:focus {
                background-image: url(../images/icon-slider-button.svg) !important;
                background-size: cover;
            }
        }
        .slick-next {
            position: absolute;
            top: 50%;
            width: 40px;
            height: 40px;
            //transform: translateY(-25px);
            background-image: url(../images/icon-slider-button.svg);
            background-size: cover;
            z-index: 10;
            right: 4px;
            &::before {
                position: absolute;
                content: "";
                width: 24px;
                height: 24px;
                background-image: url(../images/icon-slider-button_02.svg);
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            &:hover,
            &:focus {
                background-image: url(../images/icon-slider-button.svg) !important;
                background-size: cover;
            }
        }
    }
    &--type3 {
        padding-bottom: 0;
        //overflow: hidden;
        //height: 100% !important;
        a {
            display: block;
        }
        img {
            width: 100%;
        }
        #{$root}__item {
            padding-right: 0;
            padding-left: 0;
        }
        .slick-dots {
            top: 50%;
            bottom: auto;
            transform: translateY(-50%);
            li {
                display: block;
                margin: 5px 0;
            }
        }
        .slick-prev {
            display: none;
            &::before {
                display: none;
            }
        }
        .slick-next {
            display: none !important;
            right: 0;
            &::before {
                display: none;
            }
        }
        .slick-list {
            &.draggable {
                height: 100% !important;
            }
        }
        .slick-track {
            height: 100% !important;
        }
    }
    &--type4 {
        background-color: #fff;
        margin-bottom: 8px;
        padding-bottom: 0;
        padding-left: 15px;
        .slick-list {
            padding: 0 5.9% 0 0;
        }
        #{$root} {
            &__item {
                padding: 0 15px 12px 0;
            }
            &__slick-number {
                display: inline-block;
                width: 60px;
                height: 24px;
                text-align: center;
                border: solid 1px #d5dce5;
                border-radius: 10px;
                margin-top: 12px;
            }
            &__slick-text {
                margin-top: 12px;
                margin-left: 5px;
            }
        }
    }
    &--middle {
        background-color: #f5f7fa;
        margin-top: 16px;
        margin-bottom: 0;
        padding-bottom: 22px;
        .slick-dots {
            bottom: 1px;
        }
    }
    &--fc {
        margin-bottom: 8px;
    }
    &-wrap {
        padding-top: 16px;
        margin-top: 16px;
        background-color: #fff;
    }
}

.content-carousel {
    $root: &;
    padding-bottom: 42px;
    &:last-child {
        padding-bottom: 21px;
    }
    &__title {
        @include flex(center, space-between);
        padding: 0 27px 7px 15px;
        #{$root}__button {
            font-size: 12px;
            color: #999;
            line-height: 18px;
        }
    }
    &__list {
        padding-left: 15px;
    }
    &__container {
        // padding-bottom: 50px;
        // margin-bottom: -70px;
        // overflow-x: auto;
        // overflow-y: hidden;
        // -webkit-overflow-scrolling: touch;
        // white-space: nowrap;
        // font-size: 0;
        display: flex;
    }
    &__item {
        //display: inline-block;
        vertical-align: middle;
        font-size: 15px;
        &:not(:first-of-type) {
            margin-left: 12px;
        }
        &:last-of-type {
            margin-right: 15px;
        }
    }
}

.filter {
    padding: 12px 0;
    text-align: right;
    font-size: 0;
    line-height: 12px;
    &__button {
        font-size: 12px;
        position: relative;
        color: #999;
        padding-left: 10px;
        &:first-of-type {
            padding-right: 10px;
            &:after {
                content: '';
                position: absolute;
                @include vertical-center();
                right: 0;
                display: inline-block;
                width: 1px;
                height: 10px;
                background: #9da3ac;
            }
        }
        &--active {
            color: #333;
        }
    }
}

.scrap {
    &__date {
        &+.card {
            margin-top: 12px;
        }
    }
}

.tag {
    font-size: 0;
    &__box {
        display: inline-block;
        min-width: 32px;
        padding: 4px 16px;
        color: #949aa3;
        background: #f5f7fa;
        border-radius: 50px;
        font-size: 12px;
        margin-right: 6px;
        margin-top: 6px;
        &:active,
        &:hover,
        &:focus {
            color: #949aa3;
        }
        &--active {
            background-color: #0da8ff;
            color: #fff;
        }
    }
    &--type2 {
        background-color: #fff;
        padding: 8px 0 12px 0;
    }
}

.tab-content {
    display: none;
    height: 100%;
    &--active {
        display: block;
    }
}

.manual {
    height: 100%;
    //min-height: 100%;
    position: relative;
    .button {
        position: fixed;
        bottom: 0;
    }
    .welcome-text {
        font-size: 15px;
        line-height: 26px;
        text-align: center;
        padding-bottom: 70px;
    }
}

.step {
    @include flex(center, center);
    &__order {
        position: relative;
        width: 23px;
        height: 23px;
        background: #f5f7fa;
        font-size: 12px;
        color: #9da3ac;
        border-radius: 100%;
        text-align: center;
        margin: 0 9px;
        &:after {
            content: '';
            background: #f5f7fa;
            width: 20px;
            height: 2px;
            position: absolute;
            @include vertical-center();
            right: 21px;
        }
        &:first-child {
            &:after {
                display: none;
            }
        }
        &--active {
            background: #0da8ff;
            color: #fff;
            &:after {
                background: #0da8ff;
            }
        }
    }
}

.step-heading {
    text-align: center;
    padding-top: 24px;
    &__title {
        font-size: 20px;
        line-height: 29px;
        font-weight: 500;
        strong {
            color: #04d6cd;
        }
    }
    &__phrase {
        color: #9da3ac;
    }
}

.step-box {
    background: #e9fffe;
    padding: 30px 22px;
    margin: 24px 0;
    text-align: center;
    &__text {
        dd {
            padding-top: 5px;
        }
    }
}

.step-slider {
    $root: &;
    position: relative;
    width: 100%;
    min-height: 425px;
    overflow: hidden;
    &__item {
        transition: transform 500ms ease-in-out;
        transition-delay: 300ms;
        position: relative;
        img {
            width: 100%;
            max-height: 380px;
            position: relative;
        }
        &.slick-active {
            img {
                border: 2px solid #0da8ff;
                border-radius: 5px;
                overflow: hidden;
                z-index: 100000;
            }
            &+#{$root}__item {
                margin-left: -145px;
            }
        }
        &.slick-current {
            z-index: 100000;
        }
        &.slick-slide {
            &:not(.slick-active) {
                transform: scale(0.9);
                opacity: 0.7;
                z-index: 10;
                figcaption {
                    display: none;
                }
            }
        }
    }
    figcaption {
        font-size: 16px;
        line-height: 26px;
        color: #0da8ff;
        text-align: center;
        padding-top: 10px;
    }
    .slide {
        display: block;
        -webkit-user-select: none;
        position: absolute;
        //transition: ease-in-out .8s all;
        overflow: hidden;
        img {
            width: 100%;
            max-height: 385px;
        }
        figcaption {
            display: none;
            font-size: 16px;
            line-height: 26px;
            color: #0da8ff;
            text-align: center;
            padding-top: 10px;
        }
        &.center-slide {
            -webkit-user-select: none;
            z-index: 30;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            //width: 68%;
            width: 217px;
            img {
                border: 2px solid #0da8ff;
                border-radius: 5px;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            }
            figcaption {
                display: block;
            }
        }
        &.left-slide,
        &.right-slide {
            //width: 56%;
            width: 182px;
            opacity: 0.7;
            z-index: 20;
            bottom: 70px;
            img {
                max-height: 315px;
            }
        }
        &.left-slide {
            left: 0;
        }
        &.right-slide {
            right: 0;
        }
        &.left-hidden-slide,
        &.right-hidden-slide {
            width: 120px;
            height: 130px;
            z-index: 10;
            bottom: 30px;
        }
        &.left-hidden-slide {
            left: -100%;
        }
        &.right-hidden-slide {
            right: -100%;
        }
        &.hidden-slide {
            visibility: hidden;
            display: none;
        }
        /* 
        &.hidden-slide,
        &.left-hidden-slide,
        &.right-hidden-slide {
            display: none;
        } */
    }
}

.step-category {
    $root: &;
    height: calc(100% - 184px);
    overflow-y: auto;
    padding-left: 8px;
    &--type2 {
        height: calc(100% - 43px);
    }
    &__list {
        position: relative;
        padding: 15px 0;
        border-bottom: 1px solid #f5f7fa;
        &:last-child {
            border-bottom: none;
        }
    }
    &__button-wrap {
        position: absolute;
        right: 0;
        font-size: 0;
        @include vertical-center();
        height: 32px;
        #{$root}__button {
            width: 32px;
            height: 32px;
            background-image: url(../images/icon-arrow-up.svg);
            background-repeat: no-repeat;
            background-size: cover;
            margin: 0 8px;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: -8px;
                width: 1px;
                height: 95%;
                background: #f5f7fa;
            }
            &--down {
                transform: rotate(180deg);
            }
        }
    }
}

.step-checkbox {
    padding: 20px 8px 0 0;
    text-align: right;
}

.luck {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 62px;
    background-image: url(../images/background-luck.png);
    background-size: cover;
    color: #fff;
    font-weight: 500;
    &--main {
        background-image: url(../images/background-luck2.png);
        padding-top: 74px;
    }
    &__button-wrap {
        text-align: right;
    }
    &__button {
        position: absolute;
        left: 15px;
        bottom: 32px;
        width: calc(100% - 30px);
        height: 40px;
        border-radius: 5px;
        background-color: #fff;
        color: #eb8e4a;
        font-size: 18px;
        font-weight: 600;
        &--type2 {
            position: initial;
            width: calc(50% - 20px);
            background-color: #fee82f;
            color: #eb8e4a;
            margin-top: 15px;
        }
        &:disabled {
            background-color: #f9a364;
            color: #e78238;
        }
    }
    &__title {
        text-align: center;
        font-size: 25px;
        line-height: 31px;
        &--type2 {
            font-size: 18px;
            line-height: 24px;
        }
        span {
            color: #fee82f;
        }
        &+.text {
            margin-top: 21px;
        }
    }
    &__flex-box {
        margin-top: 39px;
        @include flex(start, start);
        &:first-of-type {
            margin-top: 61px;
        }
    }
    &__flex-box2 {
        @include flexbox();
        height: calc(100% - 90px);
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;
    }
    &__column {
        flex: 1;
        &:not(:first-of-type) {
            margin-left: 8px;
        }
    }
    &__radio {
        position: relative;
        display: block;
        width: 100%r;
        height: 40px;
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 16px;
        }
        input[type="radio"] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            border: solid 1px #fff;
            &:checked {
                border: solid 1px #fee82f;
                &+span {
                    color: #fee82f;
                }
            }
        }
    }
    &__select {
        width: 100%;
        padding-bottom: 11px;
        background-color: transparent;
        background-image: url(../images/icon-select02.svg);
        background-size: 18px 10px;
        background-position: right center;
        background-repeat: no-repeat;
        border-bottom: 1px solid #fff;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        &::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &::-webkit-clear-button {
            display: none;
        }
        &:not(.has-value):before {
            color: #fff;
            content: attr(placeholder);
            font-size: 16px;
        }
        &:focus {
            &::before {
                display: none;
            }
        }
        &--type2 {
            width: 105px;
        }
        &+& {
            margin-top: 40px;
        }
        &:first-of-type {
            margin-top: 45px;
        }
    }
    &__logo {
        text-align: center;
        img {
            width: 48px;
            margin-bottom: 26px;
        }
    }
    &__phrase {
        text-align: left;
        &--type2 {
            font-size: 15px;
            line-height: 22px;
        }
        &--right {
            text-align: center;
        }
        &--interval {
            padding-top: 26px;
        }
    }
    &__text {
        font-size: 14px;
        &--center {
            text-align: center;
        }
        &--large {
            font-size: 15px;
            line-height: 22px;
        }
        &--xlarge {
            font-size: 18px;
            line-height: 24px;
        }
        &--interval {
            padding-top: 26px;
        }
        &--yellow {
            color: #fee82f;
        }
    }
    &__figure {
        img {
            width: 100%;
        }
        padding: 15px 0 20px;
    }
    &__information {
        @include flex(start, center);
        margin-top: 25px;
    }
    &__right {
        margin-left: 16px;
    }
    &__icon {
        width: 44px;
        height: 44px;
        background-image: url(../images/icon-luck.svg);
        background-size: cover;
        background-repeat: no-repeat;
        &--type2 {
            background-image: url(../images/icon-luck_02.svg);
        }
    }
    &__scroll {
        height: calc(100% - 72px);
        overflow-x: auto;
        padding-bottom: 85px;
        &--type2 {
            padding-bottom: 50px;
        }
    }
    &__description {
        margin-top: 10px;
        line-height: 24px;
    }
}

@import 'mixin';
.container-cover {
    //레이아웃으로 옮기기
    min-height: 470px;
    &--type2 {
        min-height: 353px;
    }
}

.profile {
    padding: 28px 0 0;
    &--type2 {
        padding: 28px 0 20px;
    }
    &__top {
        @include flexbox();
    }
    &__image {
        width: 92px;
        height: 92px;
        background-image: linear-gradient(to bottom, #059feb, #04d6cd);
        border-radius: 100%;
        @include flex(center, center);
        overflow: hidden;
        img {
            width: 86px;
            height: 86px;
            padding: 3px;
            background-color: #fff;
            border-radius: 100%;
        }
    }
    &__value {
        @include flex(unset, center);
        flex-direction: column;
        width: calc(100% - 116px);
        margin-left: 24px;
        padding-right: 12px;
    }
    &__box {
        border-radius: 5px;
        background-color: #ffffff;
        padding: 12px 16px;
        margin-top: 12px;
        &--type2 {
            margin-top: 28px;
        }
    }
    &__row {
        margin-top: 12px;
        &:first-of-type {
            margin-top: 0;
        }
        &--type2 {
            margin-top: 6px;
        }
        &--flex {
            @include flex(center, start);
        }
        &--space-between {
            @include justify-content(space-between);
        }
        &--flex-start {
            @include align-items(start);
        }
        .icon__text {
            margin-left: 3px;
        }
    }
    &__column {
        &:last-of-type {
            margin-left: 7px;
            padding-left: 7px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                width: 1px;
                height: 16px;
                top: 3px;
                left: 0;
                background-color: #ccc;
            }
        }
        .text {
            vertical-align: auto;
        }
    }
    &__list {
        padding-left: 28px;
        position: relative;
        &::before {
            position: absolute;
            content: "";
            width: 1px;
            height: calc(100% - 18px);
            background-color: #f5f7fa;
            left: 12px;
            top: 8px;
        }
        li {
            position: relative;
            margin-top: 10px;
            &:first-of-type {
                margin-top: 0;
            }
            &::before {
                position: absolute;
                content: "";
                width: 5px;
                height: 5px;
                background-color: #0da8ff;
                border-radius: 100%;
                top: 8px;
                left: -18px;
            }
        }
        &--type2 {
            &::before {
                display: none;
            }
            li {
                &::before {
                    display: none;
                }
            }
        }
        &--type3 {
            &::before {
                height: calc(100% - 25px);
                top: 12px;
            }
            li {
                &::before {
                    top: 12px;
                }
            }
        }
    }
}

.consulting {
    position: relative;
    &--type2 {
        padding: 36px 0;
    }
    &__top {
        height: 186px;
        background-image: url(../images/backgroud-consulting.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        padding: 32px 24px;
    }
    &__title {
        font-size: 20px;
        color: #fff;
        margin-top: 2px;
    }
    &__sub-title {
        color: #fff;
        font-size: 12px;
    }
    &__flex-box {
        @include flex(baseline, start);
    }
    &__highlight {
        display: inline-block;
        font-size: 12px;
        color: #fff;
        border-radius: 5px;
        background-color: #0da8ff;
        padding: 3px 8px;
        margin-top: 12px;
    }
    &__information {
        color: #fff;
        font-size: 12px;
        margin-left: 8px;
    }
    &__floating {
        position: absolute;
        width: calc(100% - 30px);
        left: 15px;
        top: calc(186px - 18px);
        padding-bottom: 70px;
    }
    &__box {
        padding: 15px;
        margin-top: 9px;
        border-radius: 5px;
        background-color: #fff;
        background-image: url(../images/icon-consulting_01.svg);
        background-size: 54px 41px;
        background-position: right 20px center;
        background-repeat: no-repeat;
        &:first-of-type {
            margin-top: 0;
        }
        &--type2 {
            background-image: url(../images/icon-consulting_02.svg);
            background-size: 54px 44px;
        }
        &--type3 {
            background-image: url(../images/icon-consulting_03.svg);
            background-size: 54px 42px;
        }
    }
    &__explain {
        letter-spacing: normal;
    }
    &__button-wrap {
        width: 115px;
        margin-top: 10px;
    }
    &__popup {
        width: 100%;
        height: 100%;
        padding: 36px 30px;
        position: relative;
        &-title {
            font-weight: 500;
            text-align: center;
        }
        &-content {
            font-size: 12px;
            margin-top: 20px;
            padding: 0 5px;
            height: calc(100% - 100px);
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 4px;
            }
            &::-webkit-scrollbar-track {
                background-color: #f5f7fa;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #0da8ff;
                border-radius: 6px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
        &-button-wrap {
            position: absolute;
            width: calc(100% - 100px);
            bottom: 36px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.expert {
    $root: &;
    &--type2 {
        padding: 36px 0 0;
    }
    &--type3 {
        padding: 8px 0 0;
    }
    &--type4 {
        height: 100%;
    }
    &--type5 {
        padding-top: 24px;
    }
    &__box {
        &+& {
            margin-top: 35px;
        }
        &--answer {
            #{$root}__content-box {
                background-color: #e9fffe;
            }
        }
    }
    &__flex-box {
        @include flex(center, space-between);
        &+#{$root}__content-box {
            margin-top: 10px;
        }
    }
    &__content-box {
        padding: 16px;
        border-radius: 5px;
        background-color: #f5f7fa;
        word-break: break-all;
    }
    &__data {
        word-break: break-all;
    }
    &__added-file {
        font-size: 14px;
        text-decoration: underline;
        padding-left: 9px;
        margin-top: 12px;
        &+& {
            margin-top: 8px;
        }
    }
}

.bottom-box {
    width: 100%;
    display: block;
    padding-top: 24px;
    &__row {
        &+.accordion {
            margin-top: 23px;
        }
    }
}

.event {
    $root: &;
    padding: 12px 0 0;
    &__icon-wrap {
        font-size: 0;
        &+#{$root}__box {
            margin-top: 12px;
        }
    }
    &__row {
        margin-top: 32px;
        &+.form {
            margin-top: 28px;
        }
        &--type2 {
            margin-top: 66px;
        }
        &--type3 {
            margin-top: 49px;
        }
    }
    &__information-box {
        img {
            width: 100%;
        }
    }
    iframe {
        min-height: 475px;
    }
}

.notice {
    padding: 12px 0 0;
    &__popup {
        width: 100%;
        height: 100%;
        position: relative;
        &-content {
            //height: calc(100% - 47px);
            //min-height: 320px;
            .main-slider {
                padding-bottom: 0;
                &__item {
                    padding-right: 0;
                    font-size: 0;
                }
                .slick-dots {
                    bottom: 7px;
                }
            }
        }
        &-button {
            width: 50%;
            padding: 14px 0;
            background-color: #ccc;
            color: #fff;
            &--type2 {
                background-color: #0da8ff;
            }
        }
        &-button-wrap {
            @include flexbox;
        }
    }
}

.notice-view {
    &__row {
        &:not(:first-of-type) {
            margin-top: 3px;
        }
    }
    &__top {
        padding-bottom: 16px;
    }
    &__bottom {
        padding-top: 16px;
        border-top: 1px solid #333;
    }
}

.loading {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    img {
        @include position-center;
        width: 112px;
        height: 105px;
        &:last-of-type {
            width: 83px;
            height: 26px;
        }
    }
}

.content {
    &-image {
        img {
            width: 100%;
        }
        &--type2 {
            //height: 270px;
            position: relative;
        }
        &--top {
            position: relative;
            &::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 50%;
                top: 50%;
                left: 0;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5) 58%, #000000);
            }
        }
        &__bottom {
            position: absolute;
            bottom: 24px;
            left: 15px;
            width: calc(100% - 30px);
        }
        &__row {
            margin-top: 8px;
            &:first-of-type {
                margin-top: 0;
            }
            &--type2 {
                line-height: 30px;
            }
        }
    }
    &-information {
        padding: 6px 0;
        min-height: 138px;
        border-bottom: 1px solid #eee;
        border-top: 1px solid #eee;
        &__row {
            @include flex(center, space-between);
            &:not(:first-of-type) {
                margin-top: 10px;
            }
            &--type2 {
                display: block;
                margin-left: -10px;
                .text {
                    display: inline-block;
                    margin-left: 10px;
                }
            }
            .text {
                word-break: keep-all;
            }
        }
        &__column {
            &--type2 {
                width: 88px;
            }
        }
    }
    &-gallery {
        padding-top: 36px;
        &__title {
            padding: 0 0 13px;
        }
        &__list {
            display: flex;
            flex-wrap: wrap;
        }
        &__item {
            width: calc(50% - 6px);
            &:nth-of-type(2n) {
                margin-left: 12px;
                display: flex;
                justify-content: flex-end;
            }
            &:nth-of-type(n+3) {
                margin-top: 28px;
            }
        }
    }
    &-media {
        position: relative;
        //height: 100%;
        &--type2 {
            position: relative;
            height: 0;
            padding-bottom: 56.25%;
            iframe {
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }
        &__button {
            position: absolute;
            top: calc(50% + 25px);
            left: 50%;
            transform: translate(-50%, -50%);
            width: 64px;
            height: 64px;
            border-radius: 100%;
            background-color: rgba(255, 255, 255, 0.8);
            background-image: url(../images/icon-media.svg);
            background-size: 35px 34px;
            background-position: 12px 12px;
            background-repeat: no-repeat;
        }
        &__dim {
            position: absolute;
            width: 100%;
            height: calc(100% - 50px);
            top: 50px;
            left: 0;
            background-color: rgba($color: #333, $alpha: 0.2);
        }
        &__image {
            img {
                width: 100%;
            }
        }
        &__text {
            position: absolute;
            bottom: 28px;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
        }
        /* 
        video+&__button+&__dim {
            height: calc(100% - 4px);
        } */
    }
    &-article {
        padding-bottom: 36px;
        &__row {
            padding-top: 36px;
            line-height: 24px;
            &--type2 {
                padding-top: 8px;
            }
            &--type3 {
                padding-top: 12px;
            }
        }
        &__sub-text {
            padding-top: 8px;
            &--type2 {
                background-color: #f5f7fa;
                padding: 8px 0;
                margin-top: -1px;
            }
        }
        &__box {
            font-size: 17px;
            line-height: 26.5px;
            &:not(:first-of-type) {
                padding-top: 20px;
            }
        }
    }
    &-header {
        &__row {
            margin-top: 8px;
            &:first-of-type {
                margin-top: 0;
            }
            &--type2 {
                line-height: 30px;
            }
        }
    }
    /*
    &-detail {
        position: absolute;
        display: none;
        top: 0;
        background: #fff;
        width: 100%;
        height: auto;
        z-index: 102;
    } */
}

.tag-box {
    background-color: #fff;
    padding: 12px 0;
    .tag {
        height: 28px;
        padding: 0 18px;
        border-radius: 50px;
        background-color: #f5f7fa;
        font-size: 12px;
        color: #949aa3;
        &:not(:nth-of-type(3n+1)) {
            margin-left: 4px;
        }
        &:nth-of-type(n+4) {
            margin-top: 6px;
        }
        &--active {
            background-color: #0da8ff;
            color: #fff;
        }
    }
}

.tab {
    $root: &;
    &__list {
        @include flex(center, start);
        border-bottom: 1px solid #efefef;
    }
    &__panel {
        display: none;
        &.is-active {
            display: inherit;
        }
    }
    &__item {
        position: relative;
        flex: 1;
        &::before {
            position: absolute;
            content: "";
            width: 1px;
            height: 15px;
            background-color: #ccc;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        &:first-of-type {
            &::before {
                display: none;
            }
        }
        a {
            display: block;
            width: 100%;
            padding: 10px 0;
            background-color: #fff;
            text-align: center;
            color: #9da3ac;
            &.is-active {
                color: #333;
            }
        }
    }
    &--type2 {
        #{$root} {
            &__list {
                border-bottom: none;
            }
            &__item {
                &::before {
                    display: none;
                }
                a {
                    padding: 6px 0;
                    border-bottom: 2px solid #ccc;
                    &.is-active {
                        color: #0da8ff;
                        border-bottom: 2px solid #0da8ff;
                    }
                }
            }
        }
    }
    &--type3 {
        height: 100%;
        min-height: 100%;
        #{$root} {
            &__panel {
                padding-bottom: 30px;
                &:first-of-type {
                    padding-bottom: 90px;
                }
            }
        }
    }
    &--type4 {
        background-color: #fff;
        border-radius: 5px;
        overflow: hidden;
        margin-top: 12px;
        #{$root} {
            &__list {
                border-bottom: none;
                padding: 12px 16px;
            }
            &__item {
                width: calc(100% - 6px * 2 / 3);
                &:not(:first-of-type) {
                    margin-left: 6px;
                }
                &::before {
                    display: none;
                }
                a {
                    display: block;
                    height: 28px;
                    line-height: 28px;
                    padding: 0;
                    background-color: #f5f7fa;
                    border-radius: 5px;
                    color: #949aa3;
                    font-size: 12px;
                    &.is-active {
                        background-color: #0da8ff;
                        color: #fff;
                    }
                }
            }
            &__panel {
                padding-top: 0;
            }
        }
    }
    &__panel {
        padding-top: 28px;
        &--type2 {
            padding-top: 12px;
        }
    }
    &__row {
        &:not(:first-of-type) {
            margin-top: 25px;
        }
    }
}

.datepicker {
    width: 150px;
    padding: 10px 20px;
    color: #000;
    border-radius: 5px;
    border: solid 1px #efefef;
    background-color: #ffffff;
    background-image: url(../images/icon-calendar.svg);
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: right 20px center;
    &-box {
        @include flex(center, space-between);
    }
}

.login-container {
    height: 100%;
    min-height: 100%;
    &__top-box {
        min-height: 280px;
        position: relative;
        &>div {
            @include position-center;
        }
        img {
            width: 125px;
        }
    }
    &__text {
        margin-top: 9px;
    }
}

.color-box {
    padding: 16px 0;
    background-color: #e9fffe;
    &+.tab {
        margin-top: 12px;
    }
}

*+.find {
    margin-top: 12px;
}

.find {
    $root: &;
    &__title {
        padding-left: 10px;
        em {
            color: #333;
            margin-right: -3px;
        }
    }
    &__table {
        padding: 0 20px;
        margin-top: 6px;
        border-radius: 5px;
        background-color: #f5f7fa;
    }
    &__row {
        &:not(:first-of-type) {
            border-top: 1px solid #eee;
        }
    }
    &__checkbox {
        position: relative;
        display: block;
        width: 100%;
        padding: 17px 0;
        input[type="checkbox"] {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 100%;
            height: 100%;
            background-image: url(../images/icon-check.svg);
            background-size: 16px 16px;
            background-position: right center;
            background-repeat: no-repeat;
            &:checked {
                background-image: url(../images/icon-check-active.svg);
            }
        }
        span {
            &:last-of-type {
                color: #9da3ac;
                margin-left: 12px;
            }
            em {
                color: #0da8ff;
            }
        }
    }
}

.flex-box {
    @include flex(center, space-between);
    i {
        margin-left: 4px;
    }
    &__column {
        &:first-of-type {
            @include flex(start, start)
        }
    }
    &__box {
        &:last-of-type {
            margin-left: 16px;
            padding-left: 16px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                width: 1px;
                height: 15px;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background-color: #ccc;
            }
        }
    }
}

.container-background {
    background: #f5f7fa;
    min-height: calc(100% - 40px);
}

.slide-wrap {
    height: calc(100% - 165px);
    overflow-y: auto;
    margin-top: 15px;
    &--type2 {
        height: calc(100% - 56px);
        margin-top: 0;
    }
}

.alert {
    $root: &;
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    display: none;
    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 320px;
        padding: 16px;
        height: 145px;
        @include flex(center, space-between);
        flex-direction: column;
        z-index: 101;
        background-color: #fff;
        border-radius: 5px;
        overflow: hidden;
        &--type2 {
            height: auto;
            min-height: 157px;
            padding: 30px 16px 16px;
            text-align: center;
            #{$root}__text {
                height: calc(100% - 60px);
            }
            #{$root}__button-wrap {
                padding-top: 20px;
            }
        }
    }
    &__text {
        width: 85%;
        height: calc(100% - 40px);
        @include flex(center, center);
        flex-direction: column;
        text-align: center;
    }
    &__button-wrap {
        width: 220px;
    }
    &__box {
        height: calc(100% - 56px);
    }
    &__row {
        &+& {
            margin-top: 6px;
        }
    }
    &__copy {
        position: relative;
        display: inline-block;
        color: #ccc;
        padding-bottom: 5px;
        width: 220px;
        //max-width: 288px;
        word-break: break-all;
        text-align: left;
        &::after {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #707070;
        }
    }
    .button-array {
        width: 100%;
    }
}

.chart-wrap {
    max-width: 270px;
    margin: 10px auto 0;
}

.output {
    border: 1px solid #333;
    padding: 32px 17px 91px;
    background-image: url(../images/background-event.svg);
    background-size: 167px 96px;
    background-position: right 11px bottom;
    background-repeat: no-repeat;
    &__sub-title {
        position: relative;
        display: inline-block;
        font-weight: 500;
        margin-top: 20px;
        &--type2 {
            margin-top: 11px;
        }
        &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            background-color: #007ecb;
            left: 0;
            bottom: -3px;
        }
    }
    &__box {
        background-color: #f5f7fa;
        border-radius: 5px;
        padding: 12px 20px;
        margin-top: 10px;
    }
    &__list-name {
        font-size: 12px;
        font-weight: 500;
        &:last-of-type {
            margin-top: 4px;
        }
    }
    &__list {
        font-size: 12px;
        li {
            position: relative;
            padding-left: 8px;
            margin-top: -3px;
            &:first-of-type {
                margin-top: 0;
            }
            &::before {
                position: absolute;
                content: "";
                width: 2px;
                height: 2px;
                border-radius: 100%;
                background-color: #333;
                top: 9px;
                left: 0;
            }
        }
    }
    &__description {
        font-size: 12px;
        padding-left: 10px;
        margin-top: 10px;
    }
}

.video-container {
    margin: 0;
    padding-bottom: 56.25%;
    max-width: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
    object {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.sub-menu {
    &__panel {
        display: none;
        transition: top 0.05s ease-in-out;
        &.is-active {
            display: block;
        }
    }
}

#iframe {
    overflow: hidden;
    margin-bottom: -4px;
}

.group {
    height: 100%;
    @include desktop-width {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        max-width: 1050px;
        width: 100%;
        &::after {
            content: "";
            clear: both;
            display: block;
        }
    }
}

.left {
    display: none;
    @include desktop-width {
        display: block;
        width: 100%;
        max-width: 525px;
        height: 100%;
        float: left;
    }
    &__body {
        text-align: center;
        padding: 100px 112px 100px 0;
        /* margin: auto; */
        height: 100%;
        @include flex(center, center);
        flex-direction: column;
        img {
            width: 100%;
        }
    }
}

.right {
    height: 100%;
    @include desktop-width {
        float: right;
        width: 100%;
        max-width: 525px;
        border-left: 1px solid #f5f7fa;
        border-right: 1px solid #f5f7fa;
        overflow: auto;
    }
}

.web-wrap {
    width: 100%;
    height: 100%;
    min-height: 100%;
}