@font-face {
    font-family: 'notosans';
    src: url(../fonts/notosans/NotoSansKR-Regular.otf), url(../fonts/notosans/NotoSansKR-Regular.woff), url(../fonts/notosans/NotoSansKR-Regular.woff2);
    font-weight: 400;
}

@font-face {
    font-family: 'notosans';
    src: url(../fonts/notosans/NotoSansKR-Medium.otf), url(../fonts/notosans/NotoSansKR-Medium.woff), url(../fonts/notosans/NotoSansKR-Medium.woff2);
    font-weight: 500;
}

@font-face {
    font-family: 'Noto Serif KR';
    src: url(../fonts/notoserif/NotoSerifCJKkr-Medium.eot) format('embedded-opentype'), url(../fonts/notoserif/NotoSerifCJKkr-Medium.otf), url(../fonts/notoserif/NotoSerifCJKkr-Medium.woff), url(../fonts/notoserif/NotoSerifCJKkr-Medium.woff2);
    font-weight: 500;
}

@font-face {
    font-family: 'Noto Serif KR';
    src: url(../fonts/notoserif/NotoSerifCJKkr-semiBold.eot) format('embedded-opentype'), url(../fonts/notoserif/NotoSerifCJKkr-SemiBold.otf), url(../fonts/notoserif/NotoSerifCJKkr-SemiBold.woff), url(../fonts/notoserif/NotoSerifCJKkr-SemiBold.woff);
    font-weight: 600;
}

//